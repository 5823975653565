.tag-container{
    margin-right: 30px;
    padding: 3px 10px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
}

.tag-point{
    width: 10px;
    margin-right: 10px;
    border-radius: 50%;
    height: 10px;
}

.tag-text{
    text-transform: capitalize;
    margin: 0;
    font-size: 11px;
}