div[disabled]
{
  pointer-events: none;
  opacity: 0.7;

  .disabled-card {
    padding-top: 15px;
    color: maroon;
    font-size: 9px;
    font-weight: 550;
    align-items: center;
    display: inline-flex;
  }
}
