.request-editor-row {
  margin-bottom: 15px;
}

.request-editor-block {
    margin-bottom: 5px;
}

.row-title {
  margin-bottom: 3px;
}

.row-subtext {
  font-size: 11px;
  color: rgb(137, 137, 137);
}

.required:after {
  content: "*";
  color: rgb(216, 57, 80);
}

p {
  margin: 0;
}

::placeholder{
  color: #808080 !important;
}

.filepond--drop-label label{
  /* color: white; */
  text-transform: uppercase;
  font-weight: bold !important;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-size: 0.8571em;
}

.filepond--label-action {
  /* text-decoration-color: white; */
}

.filepond--panel-root {
  /* background-color: #666151 !important; */
}

.filepond--credits{
  display: none;
}