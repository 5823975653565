.myrequests-card-container {
  padding: 25px 20px;
  box-sizing: border-box;
  margin-top: 10px;
}

.myrequests-card-title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
  padding-right: 15px;
}

.myrequests-card-container h1 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  padding-left: 5px;
}

.myrequests-card-search-bar{
  margin-bottom: 20px !important;
}

.search-icon {
  color: black;
  font-weight: bold !important;
}
.request-card {
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #cccccc!important;
}

.request-card-title-row {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}

.request-card-title {
  font-weight: bold;
}

.request-card-info {
  display: flex;
  margin: 13px 0;
  justify-content: space-between;
}

.tags {
  display: flex;
}

.request-card-timestamp {
  color: #544e4e;
  font-size: 9px;
}

p {
  margin: 0;
}

.selected {
  border: 2px solid green !important;
}

.form-control:focus + .input-group-append .input-group-text{
  border: 1px  solid #DDDDDD !important;
  border-left: none !important;
}