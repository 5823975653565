.request-data-container{
    margin-top: 10px;
    padding: 13px 15px;
    text-align: justify;
}

.request-data-heading {
  display: flex;
  align-items: center;
  margin: 0;
}

.request-data-title {
  margin: 0;
  font-size: 28px;
  font-weight: bold;
  margin-right: 30px;
}

.request-data {
  margin-bottom: 20px;
}

.request-data-info-title {
    font-size: 13px;
    margin-bottom: 7px;
    color: green;
    font-weight: bold;
}

.required:after {
  content: "*";
  color: rgb(216, 57, 80);
}

.request-data-values{
    font-size: 15px;
}

.request-data-countries{
    display: flex;
}

.country-tag{
    box-sizing: border-box;
    background: #c6c6c6;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 7px;
}